import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SearchTintSvg } from "../../icons/Icon";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { SEARCH } from "../../api/Api";
import { LoadingSvg, StarSvg, SearchSmallSvg, HeaderProfileSvg, HeaderOrdersSvg, HeaderWishListSvg, HeaderRewardsSvg, HeaderGiftCardSvg, HeaderDownSvg, HeaderCartSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import { HOST } from "../../api/Api";

const SearchList = (p) => {

    return (
        Array.isArray(p.json) ?
            <ul>{p.json.map((item, index) => <li key={`${index}}-${item.match}`}><Link to={`/product/${item.productId}/${item.match}`} >{item.match}</Link></li>)}</ul>
            :
            <></>
    );
};
const showSearch = (setState) => {
    return function (state, data) {
        switch (state) {
            case 'loading':
                setState(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                //console.log('showSearch', data.data);
                setState(<SearchList json={data.data} />);
                break;
            case 'error':
                setState(null);
                break;
        }
    };
}

function HeaderSearch() {

    const dispatch = useDispatch();
    const [search, setSearch] = useState(null);
    const [term, setTerm] = useState(null);
    //let onChangeDebounced = onChange.debounce(500, false);

    function doSearch(term, stateUpdater) {
        if (term) {
            dispatch(apiRequestAsyncThunk({
                request_details: SEARCH('variant', 'name', term, 5),
                call: stateUpdater,
                messages: {
                    loading: 'searching',
                    done: 'done',
                    error: 'error'
                }
            }));
        } else {
            stateUpdater('error', null);
        }
    }
    let doSearchDebounced = doSearch.debounce(500, false);

    const handleChange = (e) => {
        //console.log('handleChange', e.key);
        if (e?.target) {
            setTerm(e.target.value);
            doSearchDebounced(e.target.value, showSearch(setSearch)).then();
        }
    };
    function clearSearch(e) {
        //console.log('keyup', e.key);
        if (e.key == 'Escape') {
            setSearch(null);
        }
    }
    useEffect(() => {
        window.document.addEventListener('keyup', clearSearch);
        return () => {
            window.document.removeEventListener('keyup', clearSearch);
        };
    },);
    return (
        <section className="header-search">
            <form action="" className="header-search-form">
                <input type="text" placeholder="Search for Products, Brands and More" onChange={handleChange} />
                <button><SearchTintSvg /></button>
            </form>
            <div className="header-search-result" onClick={() => setSearch(null)}>
                {search}
                {(search && term) && <div><Link to={`/filter/${term}`} ><SearchSmallSvg /><span>search more for {`"${term}"`}</span></Link></div>}
            </div>
        </section>
    );
}

function AppHeader(p) {

    const headerBar = useRef(null);

    useEffect(() => {
        window.makeSticky(headerBar.current);
    }, []);

    return (
        <header>
            <div ref={headerBar} className="header-bar-container" id="header-bar">
                <div className="header-bar">
                    <section className="header-logo"><Link to="/"><img src="/images/logo.svg" /><span><b>Star</b>Deal</span></Link></section>
                    <HeaderSearch />
                    <section className="header-actions">
                        <div className="header-action">
                            <a href="" onClick={(e) => e.preventDefault()}><HeaderProfileSvg /><span>Login</span>
                                <figure><HeaderDownSvg /></figure>
                            </a>
                            <nav className="login-menu">
                                <div><span>new customer ?</span><a href="">sign up</a></div>
                                <ul>
                                    <li><a href=""><HeaderProfileSvg /><span>My profile</span></a></li>
                                    <li><a href=""><HeaderOrdersSvg /><span>Orders</span></a></li>
                                    <li><a href=""><HeaderWishListSvg /><span>Wishlist</span></a></li>
                                    <li><a href=""><HeaderRewardsSvg /><span>Rewards</span></a></li>
                                    <li><a href=""><HeaderGiftCardSvg /><span>Gift cards</span></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-action">
                            <a href=""><HeaderCartSvg /><span>Cart</span></a>
                        </div>
                    </section>
                </div>
            </div>
        </header>
    );
}
/*
                    <section className="header-search">
                        <form action="" className="header-search-form">
                            <input type="text" placeholder="Search for Products, Brands and More" />
                            <button><img src="../website/public/images/header-search.svg" alt="" /></button>
                        </form>
                        <div className="header-search-result"></div>
                    </section>
                */
export default AppHeader;