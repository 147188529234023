import { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FilterControls from "./FilterControls";
import FilterItems from "./FilterItems";
import { apiRequestAsyncThunk } from '../../thunk/CommonThunk';
import { Loading } from '../Loaders';
import { LoadingSvg } from '../../icons/Icon';
import { FILTER } from '../../api/Api';

function countMatches(product, filterState) {
    let matches = 0;
    const variantDetails = product.variantDetailsJson;

    if (variantDetails) {
        for (const [key, values] of Object.entries(filterState)) {
            if (
                variantDetails[key] &&
                values.includes(variantDetails[key].toLowerCase())
            ) {
                matches++;
            }
        }
    }


    return matches;
}
const Filter = (p) => {

    const dispatch = useDispatch();
    const { term } = useParams();
    const [filterControls, setFilterControls] = useState(null);
    const [filterItems, setFilterItems] = useState(null);
    const [filter, setFilter] = useState({});
    const [variants, setVariants] = useState(null);
    const isSearchInProgress = useRef(false);

    const onFilterChange = useCallback((e) => {
        let key = Object.keys(e) ? Object.keys(e)[0] : null;
        let value = e[key];
        if (Array.isArray(value)) {
            setFilter(filter => ({ ...filter, [key]: value }));
        }
    }, [filter]);

    useEffect(() => {
        if (filter && variants) {
            console.log('FILTER_STATE', filter);
            let sorted = [...variants]
                .map(product => ({
                    ...product,
                    matchCount: countMatches(product, filter)
                }))
                .sort((a, b) => b.matchCount - a.matchCount);
            //sorted.forEach(product => {delete product.matchCount;});
            //console.log('SORTED', sorted);
            setVariants(sorted);
            setFilterItems(<FilterItems data={sorted} />)
        }

    }, [filter]);
    const showSearch = (state, data) => {
        switch (state) {
            case 'loading':
                setFilterControls(<Loading><LoadingSvg /></Loading>);
                setFilterItems(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                let controls = { brands: data.brands, filter: data.filter };
                setFilterControls(<FilterControls data={controls} onChange={onFilterChange} />);
                data.variants.forEach(variant => {
                    if (variant.variantDetailsJson) {
                        variant.variantDetailsJson = JSON.parse(variant.variantDetailsJson);
                        variant.variantDetailsJson['brands'] = variant.brand;
                    }
                });
                setVariants(data.variants);
                console.log('variants', data.variants);
                setFilterItems(<FilterItems data={data.variants} />)
                isSearchInProgress.current = false;
                break;
            case 'error':
                setFilterControls(<></>);
                setFilterItems(<></>);
                isSearchInProgress.current = false;
                break;
        }
    }
    const loadSearch = useCallback(() => {
        
        if (isSearchInProgress.current) return;
        isSearchInProgress.current = true;

        console.log('termChanged', term);
        dispatch(apiRequestAsyncThunk({
            request_details: FILTER(term),
            call: showSearch,
            messages: {
                loading: 'searching...',
                done: 'done',
                error: 'not found'
            }
        }));
    }, [term]);
    useEffect(() => {
        loadSearch();
    }, [term]);
    return (
        <section className="container">
            <div className='filter-container'>
                <div className="flex-row">
                    <div className="col-5 flex-column">
                        {filterControls}
                    </div>
                    <div className="col-19 flex-column">
                        {filterItems}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Filter;