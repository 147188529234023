
import { useEffect, useState, useRef, useCallback } from "react";
import { FilterSvg, ChevronDownSvg,  ChevronUpSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";

const CheckboxListItem = (p) => {

    const input = useRef(null);

    return (
        <div className="control-check-item">
            <label>
                <input
                    ref={input}
                    type="checkbox"
                    id={p.label}
                    name={'brand'}
                    value={p.label}
                    onChange={p.onChange}
                />
                <span>{p.label}</span>
                <b>{p.value}</b>
            </label>
        </div>
    );
};
const ControlCheckList = (p) => {

    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);

    //console.log('ControlCheckList', p.onChange, p.heading, p.data);
    useEffect(() => {
        console.log('selected', selected);
        p.onChange({[p.heading] : selected});
    }, [selected]);

    const checked = useCallback((e) => {
        console.log('checked', e.target, e.target.checked);
        if (e.target) {
            setSelected(s => {
                let selected = [...s];
                selected.indexOf(e.target.value) > -1 ?
                    (e.target.checked == false && selected.splice(selected.indexOf(e.target.value), 1)) :
                    selected.push(e.target.value);
                return selected;
            })
        }
    }, [selected]);

    const toggle = (e) => {
        //console.log('toggle', e.target);
        if(e.target){
            if(e.target.classList.contains('close')){
                e.target.classList.replace('close','open');
            }else if(e.target.classList.contains('open')){
                e.target.classList.replace('open','close');
            }
        }
    };
    return (
        <div className="control-check-list">
            <h6 onClick={toggle} className="close"><span>Filter by : </span>{` ${p.heading} `} {open ? <ChevronUpSvg /> : <ChevronDownSvg/>}</h6>
            {p.data ? Object.entries(p.data).map(([key, value], index) => {
                return <CheckboxListItem key={`${key}-${value}-${index}`} label={key} value={value} onChange={checked} />;
            }) : <></>}
        </div>
    );
};
const FilterControls = (p) => {

    const [brands, setBrands] = useState(null);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        //console.log('filter controls',p.onChange, p.data);

        if (p.data) {
            setBrands(p.data.brands ? <ControlCheckList key={'brands'} heading={'brands'} data={p.data.brands} onChange={p.onChange} /> : <></>);
            setFilter(p.data.filter);
        }
    }, [p.data]);

    const renderFilter = (f) => {
        let components = [];
        if (f) {
            for (let key in f) {
                //console.log(key, f[key]);
                for (let label in f[key]) {
                    components.push(<ControlCheckList key={`${label}`} heading={label} data={f[key][label]} onChange={p.onChange} />);
                }

            }
        }
        return components;
    };

    return (
        <div className="filter-controls">
            <div className="filter-controls-header">
                <h5>Filter</h5><FilterSvg />
            </div>
            <>{brands}</>
            <>{renderFilter(filter)}</>
        </div>
    );
};

export default FilterControls;