import { useState } from "react";
import DOMPurify from 'dompurify';
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";

const ProductDescription = (p) => {

    const [activeTab, setActiveTab] = useState("details");
    if(!p.productDetails){
        return (<Loading><LoadingSvg/></Loading>);
    }
    return (
        <section className="container">
            <section className="product-description">
                <section className="product-tabs">
                    <section className="product-tabs-head">
                        <button onClick={() => setActiveTab("details")}>Details</button>
                        <button onClick={() => setActiveTab("description")}>Description</button>
                        <button onClick={() => setActiveTab("reviews")}>Reviews</button>
                    </section>
                    <section className="product-tabs-body">
                        {activeTab === "details" && (
                            <section className="product-detail">
                                <table>
                                    <tbody>
                                        {p.productDetails && Object.entries(p.productDetails).map(([key, value]) => (
                                            <tr key={key}>
                                                <td>{key}</td>
                                                <td>{value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </section>
                        )}
                        {activeTab === "description" && (
                            <section className="product-description-content">
                                 <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(p.product?.description || 'no description') }} />
                            </section>
                        )}
                        {activeTab === "reviews" && (
                            <section className="product-reviews">
                                {p.reviews && p.reviews.length > 0 ? (
                                    p.reviews.map((review, index) => (
                                        <div key={index} className="review">
                                            <p><strong>{review.author}</strong>: {review.comment}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>No reviews yet.</p>
                                )}
                            </section>
                        )}
                    </section>
                </section>
            </section>
        </section>
    );
};

export default ProductDescription;