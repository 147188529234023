import { useEffect, useState } from "react";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { GET_MAIN_MENU } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";
import { useDispatch, useSelector } from 'react-redux';

function AppMainMenu(p) {

    const [menu, setMenu] = useState([]);
    const dispatch = useDispatch();

    const showMainMenu = (state, data) => {
        //console.log('Menu.showMainMenu', state, data?.data);
        switch (state) {
            case 'loading':
                setMenu(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                setMenu(render(data.data));
                break;
            case 'error':
                setMenu(<button className='simple-form-button' onClick={loadMainMenu}>load menu</button>);
                break;
        }
    }
    const render = (json, e) => {
        return (<ul key={`ul-${e?.id || ''}`} className={e?.ulClass || ''}>
            {Array.isArray(json) && json.map((node, index) => {
                return <li key={node.id}>
                    {(node.parent < 0) ?
                        <a href=""><img src="" /><span>{node.value}</span></a> :
                        <a href="">{node.value}</a>}
                    {node.nodes && render(node.nodes, { ulClass: 'main-nav-1', id: node.id })}
                </li>;
            })}
        </ul>);
    }
    const loadMainMenu = (e) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_MAIN_MENU(),
            call: showMainMenu,
            messages: {
                loading: 'Loading main menu',
                done: 'Menu loaded',
                error: 'Error loading menu'
            }
        }));
    }
    useEffect(() => {
        loadMainMenu();
    }, []);
    return (
        <section className="container">
            <section className="main-menu">
                <nav className="main-nav">{menu}</nav>
            </section>
        </section>
    );
}
/*
<section className="main-menu">
    <nav className="main-nav">
        <ul>
            <li>
                <a href=""><img src="../website/public/images/menu.webp" alt="" /><span>Grocery</span></a>
                <ul className="main-nav-1">
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                </ul>
            </li>
            <li>
                <a href=""><img src="../website/public/images/menu.webp" alt="" /><span>Grocery</span></a>
                <ul className="main-nav-1">
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                </ul>
            </li>
            <li>
                <a href=""><img src="../website/public/images/menu.webp" alt="" /><span>Grocery</span></a>
                <ul className="main-nav-1">
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                    <li><a href="">item 1</a></li>
                    <li><a href="">item 2</a></li>
                    <li><a href="">item 3</a></li>
                </ul>
            </li>
        </ul>
    </nav>
</section>
*/
export default AppMainMenu;