import { combineReducers, bindActionCreators } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';


const reducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.payload };
        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    reducer: reducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});


export default store;