import { Link } from "react-router-dom";
import { $discountPercentage } from "../../main";
import { WishListSvg, OpenSvg, AddToCartSvg, ImagePlaceholderSvg } from "../../icons/Icon";

const ProductCard = (p) => {
    return (
        <div className="product-showcase-item col-6">
            <Link to={`/product/${p.product.productId}/${p.product.name.replaceAll(/\s+/g, '-')}`}>
                <figure>
                    <div className="showcase-item-func">
                        <span><WishListSvg onClick={(e) => {}} /></span>
                        <span><OpenSvg /></span>
                    </div>
                    <aside>
                        <span>{$discountPercentage(p.product.retailPrice, p.product.salePrice)}%</span>
                    </aside>
                </figure>
                <div className="image-block">{p.product.image || p.product.image}</div>
                <h6>{p.product.name}</h6>
                <div>
                    <span>&#8377;{p.product.retailPrice}</span>
                    <em>&#8377;{p.product.salePrice}</em>
                    <button><AddToCartSvg onClick={(e) => {}} /></button>
                </div>
            </Link>
        </div>
    );
};

export default ProductCard;