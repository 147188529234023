import { useRef, useEffect, useState, useMemo } from "react";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { ProductArrowRightSvg, PrductArrowLeftSvg } from "../../icons/Icon";

const MySlider = (p) => {

    const [render, setRender] = useState(false);
    const [isAutoplay, setIsAutoplay] = useState(false);
    const autoplayButtonRef = useRef(null);
    const slider = useRef(null);
    const sliderNavigation = useRef(null);
    const sliderControls = useRef(null);

    const sliderSettings = {
        autoplay: false,
        loop: true,
        nav: false,
        controls: false,
        mouseDrag: true,
        items: 1,
        slideBy: "page",
        controlsContainer: sliderControls.current,
        navContainer: sliderNavigation.current,
        autoplayButton: "#item-showcase-toggle",
        onInit: (slider) => {
            console.log('onInit', slider)
        },
        onClick: (e) => {
            console.log('onClick', e)
        }
    };
    const gotoSlide = (index, e) => {
        //console.log('gotoSlide', index, slider.current, e)
        if (slider && slider.current) {
            slider.current.slider.goTo(index);
        }
    };
    const move = (there) => {
        if (slider && slider.current) {
            let info = slider.current.slider.getInfo();
            let displayIndex = parseInt(info.displayIndex);
            let slideCount = parseInt(info.slideCount);
            //console.log('gotoSlide', displayIndex, slideCount, displayIndex > 1, slideCount > displayIndex)
            switch (there) {
                case 'next':
                    if (slideCount > displayIndex) {
                        //console.log('prev', displayIndex + 1)
                        slider.current.slider.goTo(displayIndex + 1);
                    }
                    break;
                case 'prev':
                    if (displayIndex > 1) {
                        console.log('prev', displayIndex - 2)
                        slider.current.slider.goTo(displayIndex - 2);
                    }
                    break;
            }
        }
    };
    //
    const renderSlider = (images) => {
        setRender(
            <TinySlider ref={ts => slider.current = ts} settings={sliderSettings} id="item-showcase-thumbs" className="item-showcase-thumbs">
                <div><img src="/images/placeholder.jpeg" alt="Thumb 1" /></div>
                <div><img src="/images/placeholder.jpeg" alt="Thumb 2" /></div>
                <div><img src="/images/placeholder.jpeg" alt="Thumb 3" /></div>
                <div><img src="/images/placeholder.jpeg" alt="Thumb 4" /></div>
            </TinySlider >
        )
    };
    useEffect(() => {
        setRender(renderSlider(p.images));
    }, []);
    return (
        <section className="item-showcase">
            <div className="item-showcase-slider">
                {render}
                <div className="item-showcase-tool">
                    <div ref={sliderNavigation} id="item-showcase-thumbs" className="item-showcase-thumbs">
                        <div onClick={() => gotoSlide(0)}><img src="/images/placeholder.jpeg" alt="Thumb 1" /></div>
                        <div onClick={() => gotoSlide(1)}><img src="/images/placeholder.jpeg" alt="Thumb 2" /></div>
                        <div onClick={() => gotoSlide(2)}><img src="/images/placeholder.jpeg" alt="Thumb 3" /></div>
                        <div onClick={() => gotoSlide(3)}><img src="/images/placeholder.jpeg" alt="Thumb 4" /></div>
                        <div onClick={() => gotoSlide(4)}><img src="/images/placeholder.jpeg" alt="Thumb 5" /></div>
                        <div onClick={() => gotoSlide(5)}><img src="/images/placeholder.jpeg" alt="Thumb 4" /></div>
                        <div onClick={() => gotoSlide(6)}><img src="/images/placeholder.jpeg" alt="Thumb 5" /></div>
                    </div>
                    <div ref={sliderControls} id="item-showcase-controls" className="item-showcase-controls">
                        <div onClick={() => move("prev")}>
                            <PrductArrowLeftSvg />
                        </div>
                        <div onClick={() => move("next")}>
                            <ProductArrowRightSvg />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MySlider;