import { SortAscendingSvg, SortDescendingSvg, GridSvg, ListSvg } from "../../icons/Icon";
import ProductCard from "../product/ProductCard";
import ProductPaginated from "./ProductPaginated";

const toggleSelected = function (target) {
    target.classList.add('selected-display-control-button');
    if(this.previous){
        this.previous.classList.remove('selected-display-control-button');
    }
    this.previous = target;
};
const actions = {
    priceAscending: function (e) {
        e.preventDefault();

        toggleSelected.call(this, e.target);
    },
    priceDescending: function (e) {
        e.preventDefault();
        toggleSelected.call(this, e.target);
    },
    grid: function (e) {
        console.log(this);
        e.preventDefault();
        toggleSelected.call(this, e.target);
    },
    list: function (e) {
        e.preventDefault();
        toggleSelected.call(this, e.target);
    },
    previous: null
};
const items = [...Array(100).keys()];
const FilterItems = (p) => {
    //console.log('FilterItems.render', p.data);
    return (
        <div className="filter-items">
            <div className="filter-items-header">
                <div className="number-of-results">
                    <span>Showing x results</span>
                </div>
                <div className="filter-list-control-group">
                    <div className="filter-sort-controls">
                        <span>Sort By</span>
                        <button onClick={actions.priceAscending.bind(actions)}><span>Price low to high</span><SortAscendingSvg /></button>
                        <button onClick={actions.priceDescending.bind(actions)}><span>Price high to low</span><SortDescendingSvg /></button>
                    </div>
                    <div className="filter-display-controls">
                        <ul>
                            <li><button onClick={actions.grid.bind(actions)}><span>Grid</span><GridSvg /></button></li>
                            <li><button onClick={actions.list.bind(actions)}><span>List</span><ListSvg /></button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="filter-items-body">
                {/**<br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
{/*                {Array.isArray(p.data) && p.data.map((item, index) => {
                    return(<ProductCard key={index} product={item} />);
                })}*/}
                <ProductPaginated itemsPerPage={8} items={p.data} />
            </div>
        </div>
    );
};

export default FilterItems;