import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Root from "../Root";
import ProductDetails from "../components/product/ProductDetails";
import Home from "../components/Home/Home";
import Filter from "../components/filter/Filter";

function ProductRoutes() {
  return (
    <Routes>
      <Route index element={<ProductDetails />} />
      <Route path=":productId" element={<ProductDetails />} />
      <Route path=":productId/:variantName" element={<ProductDetails />} />
    </Routes>
  );
}
function FilterRoutes() {
  return (
    <Routes>
      <Route index element={<Filter />} />
      <Route path=":term" element={<Filter />} />
      <Route path=":productId/:variantName" element={<Filter />} />
    </Routes>
  );
}
function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Root />}>
          <Route path="/" element={<Home />} />
          <Route path="/product/*" element={<ProductRoutes />} />
          <Route path="/filter/*" element={<FilterRoutes />} />
          <Route path="*" element={<p>error</p>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default AppRoutes;