export const SvgIcon = () => {

};
/*
export const WishListSvg = (p) => {
    return ();
};
*/
export const SearchSmallSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path></svg>);
};
export const ChevronDownSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>);
};
export const ChevronUpSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m6.293 13.293 1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z"></path></svg>);
};
export const GridSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm5 2h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4h-4V5zM3 20a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6zm2-5h4v4H5v-4zm8 5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6zm2-5h4v4h-4v-4z"></path></svg>);
};
export const ListSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M4 6h2v2H4zm0 5h2v2H4zm0 5h2v2H4zm16-8V6H8.023v2H18.8zM8 11h12v2H8zm0 5h12v2H8z"></path></svg>);
};
export const SortAscendingSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24" ><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>);
};
export const SortDescendingSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>);
};
export const FilterSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z"></path></svg>
    );
};
export const ChevronLeftSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path></svg>
    );
};
export const ChevronRightSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
    );
};
export const StarSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m6.516 14.323-1.49 6.452a.998.998 0 0 0 1.529 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082a1 1 0 0 0-.59-1.74l-5.701-.454-2.467-5.461a.998.998 0 0 0-1.822 0L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.214 4.107zm2.853-4.326a.998.998 0 0 0 .832-.586L12 5.43l1.799 3.981a.998.998 0 0 0 .832.586l3.972.315-3.271 2.944c-.284.256-.397.65-.293 1.018l1.253 4.385-3.736-2.491a.995.995 0 0 0-1.109 0l-3.904 2.603 1.05-4.546a1 1 0 0 0-.276-.94l-3.038-2.962 4.09-.326z"></path></svg>
    );
};
export const HeaderCartSvg = (p) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.25 17.25H6.54545L3.93015 2.86584C3.89873 2.69303 3.80766 2.53673 3.67281 2.42419C3.53796 2.31164 3.36789 2.25 3.19225 2.25H1.5" stroke="#111112" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 21C8.53553 21 9.375 20.1605 9.375 19.125C9.375 18.0895 8.53553 17.25 7.5 17.25C6.46447 17.25 5.625 18.0895 5.625 19.125C5.625 20.1605 6.46447 21 7.5 21Z" stroke="#111112" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.25 21C18.2855 21 19.125 20.1605 19.125 19.125C19.125 18.0895 18.2855 17.25 17.25 17.25C16.2145 17.25 15.375 18.0895 15.375 19.125C15.375 20.1605 16.2145 21 17.25 21Z" stroke="#111112" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.86363 13.5H17.6345C17.9858 13.5 18.3259 13.3767 18.5956 13.1516C18.8653 12.9265 19.0475 12.6139 19.1103 12.2683L20.25 6H4.5" stroke="#111112" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
export const HeaderDownSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.939 7.939 12 12.879l-4.939-4.94-2.122 2.122L12 17.121l7.061-7.06z"></path></svg>
    );
};
export const HeaderGiftCardSvg = (p) => {
    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3125 4.99902H2.3125C1.9644 4.99902 1.63056 5.1373 1.38442 5.38345C1.13828 5.62959 1 5.96343 1 6.31152V18.3115C1 18.6596 1.13828 18.9935 1.38442 19.2396C1.63056 19.4857 1.9644 19.624 2.3125 19.624H20.3125C20.6606 19.624 20.9944 19.4857 21.2406 19.2396C21.4867 18.9935 21.625 18.6596 21.625 18.3115V6.31152C21.625 5.96343 21.4867 5.62959 21.2406 5.38345C20.9944 5.1373 20.6606 4.99902 20.3125 4.99902ZM2.3125 6.12402H20.3125C20.3622 6.12402 20.4099 6.14378 20.4451 6.17894C20.4802 6.2141 20.5 6.2618 20.5 6.31152V8.31152H2.125V6.31152C2.125 6.2618 2.14475 6.2141 2.17992 6.17894C2.21508 6.14378 2.26277 6.12402 2.3125 6.12402ZM20.3125 18.499H2.3125C2.26277 18.499 2.21508 18.4793 2.17992 18.4441C2.14475 18.4089 2.125 18.3613 2.125 18.3115V9.43652H20.5V18.3115C20.5 18.3613 20.4802 18.4089 20.4451 18.4441C20.4099 18.4793 20.3622 18.499 20.3125 18.499Z" fill="#212121" />
            <path d="M4.00391 10.8779H8.42225" stroke="#212121" strokeLinecap="round" />
            <path d="M4.00391 12.6709H8.42225" stroke="#212121" strokeLinecap="round" />
            <path d="M4.00391 10.8774C4.95069 10.8561 7.15987 10.8774 7.15987 12.771C7.15987 14.6646 4.95069 14.6432 4.00391 14.6646" stroke="#212121" strokeLinecap="round" />
            <path d="M7.15884 17.8924C7.37257 18.0672 7.68758 18.0357 7.86244 17.822C8.03731 17.6083 8.00581 17.2933 7.79208 17.1184L7.15884 17.8924ZM3.68729 15.052L7.15884 17.8924L7.79208 17.1184L4.32053 14.2781L3.68729 15.052Z" fill="#212121" />
        </svg>

    );
};
export const HeaderRewardsSvg = (p) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 7.49902H3.75C3.33579 7.49902 3 7.83481 3 8.24902V11.249C3 11.6632 3.33579 11.999 3.75 11.999H20.25C20.6642 11.999 21 11.6632 21 11.249V8.24902C21 7.83481 20.6642 7.49902 20.25 7.49902Z" stroke="#212121" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5 11.999V18.749C19.5 18.9479 19.421 19.1387 19.2803 19.2794C19.1397 19.42 18.9489 19.499 18.75 19.499H5.25C5.05109 19.499 4.86032 19.42 4.71967 19.2794C4.57902 19.1387 4.5 18.9479 4.5 18.749V11.999" stroke="#212121" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 7.49902V19.499" stroke="#212121" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.2469 6.43945C15.1781 7.49882 12 7.49882 12 7.49882C12 7.49882 12 4.3207 13.0594 3.25195C13.4821 2.82926 14.0554 2.5918 14.6531 2.5918C15.2509 2.5918 15.8242 2.82926 16.2469 3.25195C16.6696 3.67464 16.907 4.24793 16.907 4.8457C16.907 5.44347 16.6696 6.01676 16.2469 6.43945V6.43945Z" stroke="#212121" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.75341 6.43945C8.82216 7.49882 12.0003 7.49882 12.0003 7.49882C12.0003 7.49882 12.0003 4.3207 10.9409 3.25195C10.5182 2.82926 9.94494 2.5918 9.34716 2.5918C8.74939 2.5918 8.1761 2.82926 7.75341 3.25195C7.33073 3.67464 7.09326 4.24793 7.09326 4.8457C7.09326 5.44347 7.33073 6.01676 7.75341 6.43945Z" stroke="#212121" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
export const HeaderWishListSvg = (p) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20.249C12 20.249 2.625 14.999 2.625 8.62403C2.625 7.49705 3.01546 6.40488 3.72996 5.53334C4.44445 4.66179 5.43884 4.06472 6.54393 3.8437C7.64903 3.62268 8.79657 3.79137 9.79131 4.32106C10.7861 4.85076 11.5665 5.70874 12 6.74903V6.74903C12.4335 5.70874 13.2139 4.85076 14.2087 4.32106C15.2034 3.79137 16.351 3.62268 17.4561 3.8437C18.5612 4.06472 19.5555 4.66179 20.27 5.53334C20.9845 6.40488 21.375 7.49705 21.375 8.62403C21.375 14.999 12 20.249 12 20.249Z" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
export const HeaderOrdersSvg = (p) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 16.621V7.37727C20.9993 7.24374 20.9634 7.11277 20.8959 6.99755C20.8284 6.88234 20.7317 6.78695 20.6156 6.72102L12.3656 2.08039C12.2545 2.01621 12.1284 1.98242 12 1.98242C11.8716 1.98242 11.7455 2.01621 11.6344 2.08039L3.38437 6.72102C3.26827 6.78695 3.1716 6.88234 3.10411 6.99755C3.03663 7.11277 3.00072 7.24374 3 7.37727V16.621C3.00072 16.7545 3.03663 16.8855 3.10411 17.0007C3.1716 17.1159 3.26827 17.2113 3.38437 17.2773L11.6344 21.9179C11.7455 21.9821 11.8716 22.0159 12 22.0159C12.1284 22.0159 12.2545 21.9821 12.3656 21.9179L20.6156 17.2773C20.7317 17.2113 20.8284 17.1159 20.8959 17.0007C20.9634 16.8855 20.9993 16.7545 21 16.621V16.621Z" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5938 14.2959V9.4209L7.5 4.40527" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.8973 6.99316L12.0848 11.9994L3.10352 6.99316" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0844 11.999L12 22.0115" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export const HeaderProfileSvg = (p) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_11829_87876)">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.98047 18.6904C6.54476 17.5789 7.40581 16.6454 8.46816 15.9933C9.53052 15.3413 10.7527 14.9961 11.9992 14.9961C13.2457 14.9961 14.4679 15.3413 15.5303 15.9933C16.5926 16.6454 17.4537 17.5789 18.018 18.6904" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};
export const SearchTintSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path><path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"></path></svg>);
};
export const ProductArrowRightSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31 16l-15-15v9h-16v12h16v9z"></path></svg>);
};
export const PrductArrowLeftSvg = (p) => {
    return (<svg {...p} version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M1 16l15 15v-9h16v-12h-16v-9z"></path></svg>);
};
export const ImagePlaceholderSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"></path><path d="m10 14-1-1-3 4h12l-5-7z"></path></svg>);
};
export const AddToCartSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle><path d="M13 13h2v-2.99h2.99v-2H15V5.03h-2v2.98h-2.99v2H13V13z"></path><path d="M10 17h8a1 1 0 0 0 .93-.64L21.76 9h-2.14l-2.31 6h-6.64L6.18 4.23A2 2 0 0 0 4.33 3H2v2h2.33l4.75 11.38A1 1 0 0 0 10 17z"></path></svg>);
};
export const WishListSvg = (p) => {
    return (<svg {...p} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 20.249C12 20.249 2.625 14.999 2.625 8.62403C2.625 7.49705 3.01546 6.40488 3.72996 5.53334C4.44445 4.66179 5.43884 4.06472 6.54393 3.8437C7.64903 3.62268 8.79657 3.79137 9.79131 4.32106C10.7861 4.85076 11.5665 5.70874 12 6.74903V6.74903C12.4335 5.70874 13.2139 4.85076 14.2087 4.32106C15.2034 3.79137 16.351 3.62268 17.4561 3.8437C18.5612 4.06472 19.5555 4.66179 20.27 5.53334C20.9845 6.40488 21.375 7.49705 21.375 8.62403C21.375 14.999 12 20.249 12 20.249Z" stroke="#212121" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" /></svg>);
};
export const OpenSvg = (p) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M17.91,5.981h-0.531h-3.333v1.098l2.083-0.038l-3.296,3.256l0.721,0.756l3.295-3.254v2.157h1.06V6.511V5.981L17.91,5.981z   M17.91,14.044h-1.06v2.119l-3.295-3.217l-0.721,0.757l3.296,3.255h-2.083v1.061h3.333h0.531v-0.53V14.044L17.91,14.044z   M11.205,10.485L7.909,7.23h2.083v-1.06H6.621H6.09V6.7v3.445h1.062V7.987l3.333,3.256L11.205,10.485L11.205,10.485z M11.205,13.514  l-0.719-0.719l-3.333,3.217v-2.119H6.09v3.406v0.568h0.531l3.371-0.038v-1.098l-2.083,0.037L11.205,13.514L11.205,13.514z" />
    </svg>
    );
};
export const ImageSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path><path d="m12 12-1-1-2 3h10l-4-6z"></path></svg>);
};
export const SaveSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 21h14a2 2 0 0 0 2-2V8l-5-5H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zM7 5h4v2h2V5h2v4H7V5zm0 8h10v6H7v-6z"></path></svg>);
};
export const UploadSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11 15h2V9h3l-4-5-4 5h3z"></path><path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path></svg>);
};
export const TickCircle = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>);
};
export const EditBox = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7 17.013 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583v4.43zM18.045 4.458l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58zM9 13.417l6.03-5.973 1.586 1.586-6.029 5.971L9 15.006v-1.589z"></path><path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2z"></path></svg>);
};
export const EditPencilDark = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m16 2.012 3 3L16.713 7.3l-3-3zM4 14v3h3l8.299-8.287-3-3zm0 6h16v2H4z"></path></svg>);
};
export const SubDirectoryRight = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M14 13H8V5H6v9a1 1 0 0 0 1 1h7v3l5-4-5-4v3z"></path></svg>);
};
export const DeleteSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" /></svg>);
};
export const AddLayerSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"></path></svg>);
};
export const CloseSvg = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><polygon points="18.707,6.707 17.293,5.293 12,10.586 6.707,5.293 5.293,6.707 10.586,12 5.293,17.293 6.707,18.707 12,13.414 17.293,18.707 18.707,17.293 13.414,12 " /></svg>);
};
export const AddToList = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19 15v-3h-2v3h-3v2h3v3h2v-3h3v-2h-.937zM4 7h11v2H4zm0 4h11v2H4zm0 4h8v2H4z"></path></svg>);
};
export const PlusSymbol = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>);
};
export const MinusSymbol = (p) => {
    return (<svg {...p} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M5 11h14v2H5z"></path></svg>);
};
export const LoadingSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a10" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stopColor="#6F7474"></stop>
                <stop offset=".3" stopColor="#6F7474" stopOpacity=".9"></stop>
                <stop offset=".6" stopColor="#6F7474" stopOpacity=".6"></stop>
                <stop offset=".8" stopColor="#6F7474" stopOpacity=".3"></stop>
                <stop offset="1" stopColor="#6F7474" stopOpacity="0"></stop>
            </radialGradient>
            <circle
                transform-origin="center"
                fill="none"
                stroke="url(#a10)"
                strokeWidth="15"
                strokeLinecap="round"
                strokeDasharray="200 1000"
                strokeDashoffset="0"
                cx="100"
                cy="100"
                r="70"
            >
                <animateTransform
                    type="rotate"
                    attributeName="transform"
                    calcMode="spline"
                    dur="0.7s"
                    values="360;0"
                    keyTimes="0;1"
                    keySplines="0 0 1 1"
                    repeatCount="indefinite"
                ></animateTransform>
            </circle>
            <circle
                transform-origin="center"
                fill="none"
                opacity=".2"
                stroke="#6F7474"
                strokeWidth="15"
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="70"
            ></circle>
        </svg>
    );
};


/*
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a10" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#6F7474"></stop><stop offset=".3" stop-color="#6F7474" stop-opacity=".9"></stop><stop offset=".6" stop-color="#6F7474" stop-opacity=".6"></stop><stop offset=".8" stop-color="#6F7474" stop-opacity=".3"></stop><stop offset="1" stop-color="#6F7474" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a10)" strokeWidth="15" strokeLinecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="0.7" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#6F7474" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
*/