import { Link } from "react-router-dom";
import { ImagePlaceholderSvg } from "../../icons/Icon";
import { IMAGE_HOST } from "../../api/Api";

const ProductAsideList = (p) => {

    const renderProducts = (products) => {

        return (
            Array.isArray(products) ? products.slice(0, 4).map((p, i) => {

                let image = null;
                if (Array.isArray(p.images) && (p.images.length > 0)) {
                    image = <img src={`${IMAGE_HOST}/${p.images[0].url}`} alt="" />
                } else {
                    image = <ImagePlaceholderSvg />;
                }
                console.log('ProductAsideList',p.images);
                return (
                    <div key={`${i}-${p.id}`} className="trending-product">
                        <figure>{image}</figure>
                        <div>
                            <Link to={`/product/${p.productId}/${p.name.replaceAll(/\s+/g, '-')}`}>{p.name}</Link>
                            <span>&#8377;{p.retailPrice}</span>
                        </div>
                    </div>
                );
            }) : <></>
        );
    };
    return (
        <div className="home-trending">
            <div className="product-showcase-head">
                <h3>{p.name}</h3>
            </div>
            <div className="trending-products">
                {renderProducts(p.products)}
            </div>
        </div>
    )
}
export default ProductAsideList;