import { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProductDescription from "./ProductDescription";
import ProductShowcase from "./ProductShowcase";
import ProductInformation from "./ProductInformation";
import { apiRequestAsyncThunk } from '../../thunk/CommonThunk';
import { GET_PRODUCT_AND_VARIANTS } from '../../api/Api';
import { Loading } from '../Loaders';
import { LoadingSvg } from '../../icons/Icon';
import MySlider from '../widgets/MySlider';
import VariantSelector from './VariantSelector';
import { precomputedData } from './VariantSelector';
import ProductCarousel from '../widgets/ProductCarousel';

const variantDetails = {
    "25": {
        "color": "Burgundy",
        "cpu": "981",
        "ram": "16-GB",
        "storage type": "376"
    },
    "26": {
        "color": "Green",
        "cpu": "529",
        "ram": "16-GB",
        "storage type": "178"
    },
    "27": {
        "color": "Peach",
        "cpu": "462",
        "ram": "6-GB",
        "storage type": "243"
    }
};

function createCombinations(variants) {
    const combinations = {};

    Object.values(variants).forEach(variant => {
        Object.entries(variant).forEach(([key, value]) => {
            if (!combinations[key]) {
                combinations[key] = {};
            }

            if (!combinations[key][value]) {
                combinations[key][value] = {};
            }

            Object.entries(variant).forEach(([innerKey, innerValue]) => {
                if (key !== innerKey) {
                    if (!combinations[key][value][innerKey]) {
                        combinations[key][value][innerKey] = new Set();
                    }
                    combinations[key][value][innerKey].add(innerValue);
                }
            });
        });
    });

    Object.entries(combinations).forEach(([key, values]) => {
        Object.entries(values).forEach(([value, relatedOptions]) => {
            Object.entries(relatedOptions).forEach(([innerKey, innerValues]) => {
                relatedOptions[innerKey] = Array.from(innerValues);
            });
        });
    });

    return combinations;
}
function getVariantId(variants, selectedOptions) {
    console.log("getVariantId", variants, selectedOptions);
    let id = (variants) ? Object.entries(variants)
        .filter(([variantId, attributes]) =>
            Object.entries(selectedOptions).every(
                ([key, value]) => attributes[key] === value
            )
        )
        .map(([variantId]) => variantId) : null;
    console.log("getVariantId.id", id);
    return (Array.isArray(id) && id.length > 0) ? id[0] : null;
}
//const selectedOptions = { color: "Burgundy", ram: "16-GB" }; // User selection
//const matchingVariantIds = getVariantIds(variantDetails, selectedOptions);
//const precomputedCombinations = createCombinations(variantDetails);
//console.log(precomputedCombinations);

const displayPrice = (variant) => {
    let now = (p) => <span className="price-status-now-price">&#8377;{p}</span>;
    let then = (p) => <span className="price-status-then-price">&#8377;{p}</span>;

    if (variant) {
        if (variant.salePrice < variant.retailPrice) {
            return (
                <>
                    {variant.salePrice > 0 ? now(variant.salePrice) : ''}
                    {variant.retailPrice > 0 ? then(variant.retailPrice) : ''}
                </>
            );
        } else {
            return (<>{variant.retailPrice > 0 ? now(variant.retailPrice) : ''}</>)
        }
    }
    return '';
}
const findVariantByName = (variants, variantName) => {
    if (variants && variantName) {
        let name = variantName.replaceAll(/\-/g, " ");
        let variant = variants.find(variant => {
            if (variant.name === name) {
                return variant;
            }
        });
        return variant;
    }
}
const ProductDetails = (p) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { productId, variantName } = useParams();
    const [data, setData] = useState(null);
    const [product, setProduct] = useState(null);
    const [variants, setVariants] = useState([]);
    const [variant, setVariant] = useState(null);
    const [variantDetails, setVariantDetails] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [combinations, setCombinations] = useState(null);
    const [images, setImages] = useState(null);
    const [selectedCombination, setSelectedCombination] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const loadProductById = (productId, stateUpdater) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_PRODUCT_AND_VARIANTS(productId),
            call: stateUpdater,
            messages: {
                loading: 'Loading product',
                done: 'Product loaded',
                error: 'Error loading product'
            }
        }));
    }
    const showProduct = (setState) => {
        return function (state, data) {
            switch (state) {
                case 'loading':
                    setState(<Loading><LoadingSvg /></Loading>);
                    break;
                case 'done':
                    console.log('showProduct', data.data);
                    setState(data.data);
                    break;
                case 'error':
                    setState(<button className='simple-form-button' onClick={() => { }}>Load product</button>);
                    break;
            }
        };
    }
    useEffect(() => {
        if (productId > 0) {
            loadProductById(productId, showProduct(setData));
        }
        return () => {
            console.log("loadProductById component unmounted");
        };
    }, [productId]);

    useEffect(() => {
        let variant = findVariantByName(variants, variantName);
        console.log("variantName.change", variant);
        return () => {
            console.log("ProductDetails component unmounted");
        };
    }, [variantName]);
    const onSelect = useCallback((selections) => {
        setSelectedCombination(selections);
    }, []);
    useEffect(() => {
        //console.log("SelectedCombination.changed", selectedCombination, variantDetails);
        if (selectedCombination != null && variantDetails != null) {
            let variantId = getVariantId(variantDetails, selectedCombination);
            //console.log("SelectedCombination.variantId", variantId);
            if (variantId > 0 && variantDetails[variantId]) {
                let variant = variants.find(variant => variant.id == variantId);
                let path = `/product/${product.productId}/${variant.name.replaceAll(/\s+/g, '-')}`;
                setImages(variant?.images || []);
                setVariant(variant);
                //console.log('navigate',variantName, variant,path, location.pathname);
                if (path != location.pathname) {
                    navigate(path);
                }
            }
        }
    }, [selectedCombination, variantDetails, variants]);
    useEffect(() => {
        if (data && ('product' in data)) {
            //console.log("useEffect.data", data);
            setProduct(data.product);
            setVariants(data.variants);
            setVariantDetails(data.variantDetails || null);
            setProductDetails(data.productDetails);
            //loadCombination(data.variantDetails);
            console.log('data.variantDetails', Object.keys(data.variantDetails));
            if (data.variantDetails) {
                let combination = data.variantDetails ? createCombinations(data.variantDetails) : null;
                //console.log("loadCombination", combination);
                let selected = null;
                let selectedVariant = null;
                if (combination) {
                    //console.log('navigate',variantName, variant, location.pathname);
                    if (variantName && data.variantDetails) {
                        
                        selectedVariant = findVariantByName(data.variants, variantName);
                        console.log('navigate',data.variants, variantName, selectedVariant);
                        selected = data.variantDetails[selectedVariant.id];
                    } else {
                        const [id, firstValue] = Object.entries(data.variantDetails)[0];
                        selectedVariant = data.variantDetails[id];
                        selected = firstValue;
                    }
                    setCombinations(combination);
                    setSelectedCombination(selected);
                    setVariant(selectedVariant);
                    //console.log("setSelectedCombination", combination, selected, variantName);
                }
            }
        }
    }, [data]);

    return (
        <>
            <section className="container">
                <section className="product-information">
                    <div className="flex-row">
                        <div className="col-12 flex-column">
                            <div className="item-showcase carousel-product">
                                <div className="carousel-container sticky-item-showcase">
                                    <ProductCarousel images={images} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 flex-column">
                            <section className="product-details">
                                <h2>{variant?.name || product?.name || ''}</h2>
                                <div className="product-identity">
                                    <section className="model-info">
                                        <span>Brand: {product?.brand || '...'}</span>
                                        <span>Product Code: {product?.sku || ''}</span>
                                    </section>
                                    <section >
                                        <VariantSelector data={combinations} selected={selectedCombination} onSelect={setSelectedCombination} />
                                    </section>
                                    <section className="rating-reviews">
                                        <section className="star-rating-container">
                                            <div className="star-rating">
                                                <div className="star"><div className="percent" style={{ width: '100%' }}></div></div>
                                                <div className="star"><div className="percent" style={{ width: '100%' }}></div></div>
                                                <div className="star"><div className="percent" style={{ width: '50%' }}></div></div>
                                                <div className="star"><div className="percent" style={{ width: '0%' }}></div></div>
                                                <div className="star"><div className="percent" style={{ width: '0%' }}></div></div>
                                            </div>
                                        </section>
                                        <section className="review-count">
                                            <span>0 review</span>
                                        </section>
                                    </section>
                                    <section className="stock-status">
                                        <span className="in-stock">in stock</span>
                                        <span className="out-stock">out of stock</span>
                                    </section>
                                    <section className="price-status">
                                        {displayPrice(variant)}
                                    </section>
                                    <section className="order-controls">
                                        <div>
                                            <section className="order-controls-quantity">
                                                <button>-</button>
                                                <input type="text" defaultValue={"1"} />
                                                <button>+</button>
                                            </section>
                                            <button className="order-controls-button green"><img src="../website/public/icons/add-card-green.svg" alt="" />Add to Cart</button>
                                            <button className="order-controls-button pink"><img src="../website/public/icons/heart-pink.svg" alt="" />Add to Wishlist</button>
                                            <button className="order-controls-button grey"><img src="../website/public/icons/compare.svg" alt="" />Compare</button>
                                        </div>
                                        <div>
                                            <section className="delivery-info">
                                                <img src="../website/public/icons/deliver.svg" alt="" /><span>0 days delivery</span>
                                            </section>

                                        </div>
                                    </section>
                                </div>
                            </section>
                            <ProductDescription productDetails={productDetails} product={product} />
                        </div>
                    </div>
                </section>
            </section>

            <ProductShowcase />
        </>
    );
};
/*
                                <ol className="product-variants">
                                    {variantDetails && Object.entries(variantDetails).map(([key, detail]) => {
                                        let found = variants.find(variant => variant.id == key);
                                        let match = (found.name && found.name.replaceAll(/\-/g, ' ') == found.name) ? true : false;
                                        if (match) {
                                            //setVariant(found);
                                        }
                                        //console.log('found', found.name, found)
                                        return (
                                            <li key={`${found.name}-${found.id}`} style={(match) ? { opacity: '1' } : { opacity: 0.5 }}>
                                                {<span><b>Name:</b> {found.name}</span>}
                                                <Link to={`/product/${product.productId}/${found.name.replaceAll(/\s+/g, '-')}`} key={`${found.name}`}>{Object.entries(detail).map(([name, value]) => (
                                                    <span key={`${name}`}> <b>{name} : </b>{value} </span>
                                                ))}</Link>
                                            </li>
                                        )
                                    })}
                                </ol>
*/
export default ProductDetails;