import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { GET_VARIANTS_BY_GROUP_NAME, GET_FEATURED } from "../../api/Api";
import { LoadingSvg, StarSvg } from "../../icons/Icon";
import { Loading } from "../Loaders";
import ProductShowCase from "./ProductShowCase";
import ProductAsideList from "./ProductAsideList";
import AppBanner from "../app/AppBanner";
import { SmallFeatured, SuperDiscount } from "./AllFeatured";

const Home = () => {

    const dispatch = useDispatch();
    const [trending, setTrending] = useState(null);
    const [featured, setFeatured] = useState(null);
    const [bestSellers, setBestSellers] = useState(null);
    const [newProducts, setNewProducts] = useState(null);
    const [highDemand, setHighDemand] = useState(null);
    const [hotPrices, setHotPrices] = useState(null);

    const loadFeatured = () => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_FEATURED,
            call: putFeatured
        }));
    }
    const putFeatured = (state, data) => {
        switch (state) {
            case 'loading':
                setFeatured(null);
                break;
            case 'done':
                console.log('featured', data);
                setFeatured(data?.data);
                break;
            case 'error':
                setFeatured(null);
                break;
        }
    };
    const loadGroupByName = (name, stateUpdater) => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_VARIANTS_BY_GROUP_NAME(name),
            call: stateUpdater,
            messages: {
                loading: 'Loading product',
                done: 'Product loaded',
                error: 'Error loading product'
            }
        }));
    }
    const showGroup = (setState, component) => {
        return function (state, data) {
            switch (state) {
                case 'loading':
                    setState(<Loading><LoadingSvg /></Loading>);
                    break;
                case 'done':
                    setState(component(data.data));
                    break;
                case 'error':
                    setState(<button className='simple-form-button' onClick={() => { }}>Load product</button>);
                    break;
            }
        };
    }
    useEffect(() => {
        loadFeatured();
        loadGroupByName('hot prices', showGroup(setHotPrices, (data) => <ProductShowCase name={'hot prices'} products={data} />));
        loadGroupByName('new products', showGroup(setNewProducts, (data) => <ProductShowCase name={'new products'} products={data} />));
        loadGroupByName('trending', showGroup(setTrending, (data) => <ProductAsideList name={'trending'} products={data} />));
        loadGroupByName('best sellers', showGroup(setBestSellers, (data) => <ProductShowCase name={'best sellers'} products={data} />));
        loadGroupByName('high demand', showGroup(setHighDemand, (data) => <ProductShowCase name={'high demand'} products={data} />));
    }, []);
    return (
        <>
            <AppBanner />
            <section className="container-narrow">

                <section className="flex-row">
                    <aside className="col-7">
                        <div className="home-features">
                            <ul>
                                <li>
                                    <StarSvg />
                                    <div>
                                        <h6>International Shipment</h6>
                                        <p>Your orders are shipped seamlessly between countries</p>
                                    </div>
                                </li>
                                <li>
                                    <StarSvg />
                                    <div>
                                        <h6>Extended 45 day returns</h6>
                                        <p>You have the right to return your orders within 45 days.</p>
                                    </div>
                                </li>
                                <li>
                                    <StarSvg />
                                    <div>
                                        <h6>Secure Payment</h6>
                                        <p>Your payments are secure with our private security network.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="super-discount-card">
                            {featured && <SuperDiscount json={featured.find(f => f.featuredName == 'verticalLeft') || {}} />}
                        </div>
                        <div className="deal-of-the-week">
                            <div className="product-head">
                                <h3>Deal of the week</h3>
                            </div>
                            <div className="deal-of-week">
                                <a href="">
                                    <figure>
                                        <img src="../website/public/images/phone.webp" alt="" />
                                        <div className="showcase-item-func">
                                            <span><img src="../website/public/images/header-wishlist.svg" alt="" /></span>
                                            <span><img src="../website/public/images/home-open.svg" alt="" /></span>
                                            <span><img src="../website/public/images/header-wishlist.svg" alt="" /></span>
                                        </div>
                                        <aside>
                                            <span>00%</span>
                                        </aside>
                                    </figure>
                                    <h6>product title and product name</h6>
                                    <div>
                                        <span>$000</span>
                                        <em>$0000</em>
                                        <button><img src="../website/public/images/add-to-cart.svg" alt="" /></button>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {trending}
                    </aside>
                    <section className="col-17">
                        <section className="flex-row ome-featured-discounts">
                            <div className="col-12">
                                {featured && <SmallFeatured json={featured.find(f => f.featuredName == 'firstLeft') || {}} />}
                            </div>
                            <div className="col-12">
                                {featured && <SmallFeatured json={featured.find(f => f.featuredName == 'firstRight') || {}} />}
                            </div>
                        </section>
                        <section className="home-beside-container">
                            {newProducts}
                        </section>
                        <section className="home-featured-discounts">
                            {featured && <SmallFeatured json={featured.find(f => f.featuredName == 'horizontalRight') || {}} />}
                        </section>
                        <section className="home-beside-container">
                            {bestSellers}
                        </section>
                        <section className="home-beside-container">
                            {highDemand}
                        </section>
                        <section className="home-beside-container">
                            {hotPrices}
                        </section>
                    </section>
                </section>
            </section>
        </>

    );
};

export default Home;