import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { apiSubmit } from '../main';

export const apiRequestAsyncThunk = createAsyncThunk('apiRequestAsyncThunk/do',
  async ({ request_details, call, messages = { loading: 'Loading...', done: 'Loaded.', error: 'Loading failed!' } }, { rejectWithValue }) => {
    //console.log('apiRequestAsyncThunk.try', request_details);
    let response = null;
    try {
      /*var loading = toast.loading(messages.loading, {
        closeOnClick: true,
        closeButton: true
      });*/
      call && call('loading');
      response = await apiSubmit(request_details);
      //console.log('apiRequestAsyncThunk.response', response?.content);

      if (response) {
        call && call('done', response?.content);
        /*toast.update(loading, {
          render: messages.done,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });*/
      }

      return response;
    } catch (error) {
      call && call('error');
      /*toast.update(loading, {
        render: messages.error,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });*/
      console.log('apiRequestAsyncThunk.error', error);
      //return rejectWithValue(error.message);
      return '';
    }
  }
);