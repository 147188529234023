import { IMAGE_HOST } from "../../api/Api";

export const SmallFeatured = (p) => {
    return (
        <div className="home-featured-discount">
            <div className="home-featured-discount-content">
                <img src={IMAGE_HOST + p?.json?.image?.url || ''} alt="" />
            </div>
            <div className="home-featured-discount-overlay">
                <span>{p?.json?.title || ''}</span>
                <h4>{p?.json?.name || ''}</h4>
                <p>{p?.json?.description || ''}</p>
                <a href={p?.json?.link || ''}>Shop Now</a>
            </div>
        </div>
    );
}

export const SuperDiscount = (p) => {
    return (
        <div className="super-discount">
            <div className="super-discount-content">
                <img src={IMAGE_HOST + p?.json?.image?.url || ''} alt="" />
            </div>
            <div className="super-discount-overlay">
                <span>{p?.json?.title || ''}</span>
                <h4>{p?.json?.name || ''}</h4>
                <p>{p?.json?.description || ''}</p>
                <a href={p?.json?.link || ''}>Shop Now</a>
            </div>
        </div>
    );
}