function AppFooter(p) {
    return (
        <footer className="footer-global">
            <section className="footer-global-contain">
                <section className="footer-text-links">
                    <section className="footer-text-row">
                        <section className="footer-important-links">
                            <h6>Company</h6>
                            <ul>
                                <li><a href="">contact us</a></li>
                                <li><a href="">About us</a></li>
                            </ul>
                        </section>
                        <section className="footer-important-links">
                            <h6>Countries</h6>
                            <ul>
                                <li><a href="">USA</a></li>
                                <li><a href="">UK</a></li>
                                <li><a href="">Australia</a></li>
                                <li><a href="">Canada</a></li>
                                <li><a href="">Ireland</a></li>
                                <li><a href="">Germany</a></li>
                                <li><a href="">Singapore</a></li>
                                <li><a href="">Europe</a></li>
                            </ul>
                        </section>
                        <section className="footer-important-links">
                            <h6>Services</h6>
                            <ul>
                                <li><a href="">Profile building</a></li>
                                <li><a href="">University selection</a></li>
                                <li><a href="">Document editing</a></li>
                                <li><a href="">Application assistance</a></li>
                                <li><a href="">Scholarship assistance</a></li>
                                <li><a href="">Interview preparation</a></li>
                                <li><a href="">Visa guidance</a></li>
                                <li><a href="">Pre departure</a></li>
                                <li><a href=""></a></li>
                            </ul>
                        </section>
                        <section className="footer-important-links">
                            <h6>Services</h6>
                            <ul>
                                <li><a href="">Profile building</a></li>
                                <li><a href="">University selection</a></li>
                                <li><a href="">Document editing</a></li>
                                <li><a href="">Application assistance</a></li>
                            </ul>
                        </section>
                    </section>
                    <section className="footer-text-row">
                        <section className="footer-connect-aside">
                            <picture><img src="../website/public/images/logo.svg" alt="" /></picture>
                            <section className="footer-connect-follow">
                                <h6>Connect With Us.</h6>
                                <ul>
                                    <li><a href=""><img src="images/icon/icon-footer-facebook.png" alt="" /></a></li>
                                    <li><a href=""><img src="images/icon/icon-footer-instagram.png" alt="" /></a></li>
                                    <li><a href=""><img src="images/icon/icon-footer-linkedin.png" alt="" /></a></li>
                                    <li><a href=""><img src="images/icon/icon-footer-telegram.png" alt="" /></a></li>
                                    <li><a href=""><img src="images/icon/icon-footer-youtube.png" alt="" /></a></li>
                                </ul>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <section className="footer-global-footer">
                <div>
                    <ul>
                        <li><a href="">Privacy policy</a></li>
                        <li><a href="">Terms and conditions</a></li>
                    </ul>
                </div>
                <div><span>&#xA9;
                    <script>document.write(new Date().getFullYear())</script> Company Name Pvt. Ltd.
                </span></div>
                <div></div>
            </section>
        </footer>
    );
}

export default AppFooter