import React, { useState, useEffect, forwardRef } from "react";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";

// Sample precomputed data
export const precomputedData = {
  color: {
    Burgundy: {
      cpu: ["981"],
      ram: ["16-GB"],
      "storage type": ["376"],
    },
    Green: {
      cpu: ["529"],
      ram: ["16-GB"],
      "storage type": ["178"],
    },
    Peach: {
      cpu: ["462"],
      ram: ["6-GB"],
      "storage type": ["243"],
    },
  },
  cpu: {
    "981": {
      color: ["Burgundy"],
      ram: ["16-GB"],
      "storage type": ["376"],
    },
    "529": {
      color: ["Green"],
      ram: ["16-GB"],
      "storage type": ["178"],
    },
    "462": {
      color: ["Peach"],
      ram: ["6-GB"],
      "storage type": ["243"],
    },
  },
  ram: {
    "16-GB": {
      color: ["Burgundy", "Green"],
      cpu: ["981", "529"],
      "storage type": ["376", "178"],
    },
    "6-GB": {
      color: ["Peach"],
      cpu: ["462"],
      "storage type": ["243"],
    },
  },
  "storage type": {
    "376": {
      color: ["Burgundy"],
      cpu: ["981"],
      ram: ["16-GB"],
    },
    "178": {
      color: ["Green"],
      cpu: ["529"],
      ram: ["16-GB"],
    },
    "243": {
      color: ["Peach"],
      cpu: ["462"],
      ram: ["6-GB"],
    },
  },
};


const VariantSelector = (p) => {

  //console.log("VariantSelector.initialSelections:", p.selected);
  const [selections, setSelections] = useState(p.selected || {});

  useEffect(() => {
    return () => {
      console.log("VariantSelector component unmounted");
    };
  }, []);
  useEffect(() => {
    if (p.selected) {
      setSelections(p.selected);
    }
  }, [p.selected]);

  useEffect(() => {
    //console.log("VariantSelector.Selections:", selections === p.selected);
    p.onSelect && p.onSelect(selections);
  }, [selections]);

  const handleSelection = (category, value) => {
    const newSelections = { ...selections, [category]: value };

    Object.keys(p.data).forEach((key) => {
      if (key !== category) {
        const validOptions = p.data[category][value][key];
        if (!validOptions.includes(newSelections[key])) {
          newSelections[key] = validOptions[0];
        }
      }
    });

    setSelections(newSelections);
  };

  if (!p.data || !p.selected) {
    return <Loading><LoadingSvg/></Loading>;
  }

  return (
    <div className='variant-selector'>
      {(p.data != null && p.selected != null) && Object.keys(p.data).map((category) => (
        <div key={category}>
          <h3>
            <span>{category.charAt(0).toUpperCase() + category.slice(1)}:{" "}</span>
            {selections[category]}
          </h3>
          <div>
            {Object.keys(p.data[category]).map((option) => {
              const isValid = p.data[category][option];
              return (
                <button
                  key={option}
                  onClick={() => handleSelection(category, option)}
                  disabled={!isValid}
                  className={selections[category] === option ? 'selected' : 'default'}
                >
                  {option}
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VariantSelector;
