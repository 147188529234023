import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apiRequestAsyncThunk } from "../../thunk/CommonThunk";
import { GET_SLIDER } from "../../api/Api";
import SliderCarousel from "../widgets/SliderCarousel";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";

const AppBanner = () => {

    const [slider, setSlider] = useState([]);
    const dispatch = useDispatch();

    const images = [
        {
            url: 'images/slider1.webp'
        },
        {
            url: 'images/slider2.webp'
        },
        {
            url: 'images/slider3.webp'
        }
    ];
    const loadSlider = () => {
        dispatch(apiRequestAsyncThunk({
            request_details: GET_SLIDER,
            call: showSlider
        }));
    }
    const showSlider = (state, data) => {
        switch (state) {
            case 'loading':
                setSlider(<Loading><LoadingSvg /></Loading>);
                break;
            case 'done':
                
                let slides = data.data
                    .sort((a, b) => a.order - b.order)
                    .map(slide => ({...slide.image, link: slide.link}));
                    console.log('showsSlider', slides);
                setSlider(<SliderCarousel images={slides} />);
                break;
            case 'error':
                setSlider(<></>);
                break;
        };
    }
    useEffect(() => {
        loadSlider();
    }, []);
    return (
        <section className="home-section-merge">
            <section className="banner-container">
                {/*<SliderCarousel images={images} /> */}
                {slider}
            </section>
        </section>
    );
};
export default AppBanner;