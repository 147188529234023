import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IMAGE_HOST } from '../../api/Api';
import { ProductArrowRightSvg, PrductArrowLeftSvg, ChevronLeftSvg, ChevronRightSvg } from '../../icons/Icon';
import { Loading } from '../Loaders';
import { LoadingSvg } from '../../icons/Icon';

const ProductCarousel = (p) => {
    const ArrowPrevious = (onClickHandler, hasPrev, label) => {
        return hasPrev && (
            <figure
                onClick={onClickHandler}
                title={label}
                className='arrowleft'><ChevronLeftSvg />
            </figure>
        )
    };
    const ArrowNext = (onClickHandler, hasNext, label) => {
        return hasNext && (
            <figure
                onClick={onClickHandler}
                title={label}
                className='arrowright'><ChevronRightSvg />
            </figure>
        )
    }
    const indicator = (onClickHandler, isSelected, index, label) => {
        return (
            <li
                style={{
                    background: isSelected ? '#ffffff' : '#bdbdbd',
                    borderRadius: '50%',
                    width: 10,
                    height: 10,
                    display: 'inline-block',
                    margin: '0 5px',
                    cursor: 'pointer',
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`Go to slide ${label}`}
            />
        );
    };

    if (!Array.isArray(p.images)) return <Loading><LoadingSvg /></Loading>;

    return (
        <Carousel
            showArrows={false}
            renderArrowPrev={ArrowPrevious}
            renderArrowNext={ArrowNext}
            showThumbs={true}
            showIndicators={false}
            renderIndicator={indicator}
            thumbWidth={80}
            infiniteLoop={false}
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance={true}
        >
            {Array.isArray(p.images) && p.images.map((image, index) => {

                let src = `${IMAGE_HOST}/${image.url}`;
                //console.log('Carousel.src', src);
                return (
                    <div key={index}>
                        <img src={src} alt={`Slide ${index + 1}`} />
                        {/**<p className="legend">{image.legend}</p>*/}
                    </div>
                );

            })}
        </Carousel>
    );
};

export default ProductCarousel;