const ProductShowcase = (p) => {
    return (
        <section className="container">
            <section className="product-detail-showcase">
                <div className="product-showcase-head">
                    <h3>Recently Viewed products</h3>
                    <a href="">View all</a>
                </div>
                <div className="product-showcase-body">
                    <div className="product-showcase-item">
                        <a href="">
                            <figure>
                                <img src="../website/public/images/phone.webp" alt=""/>
                                    <div className="showcase-item-func">
                                        <span><img src="../website/public/images/header-wishlist.svg" alt=""/></span>
                                        <span><img src="../website/public/images/home-open.svg" alt=""/></span>
                                        <span><img src="../website/public/images/header-wishlist.svg" alt=""/></span>
                                    </div>
                                    <aside>
                                        <span>00%</span>
                                    </aside>
                            </figure>
                            <h6>product title and product name</h6>
                            <div>
                                <span>$000</span>
                                <em>$0000</em>
                                <button><img src="../website/public/images/add-to-cart.svg" alt=""/></button>
                            </div>
                        </a>
                    </div>
                    <div className="product-showcase-item">
                        <a href="">
                            <figure>
                                <img src="../website/public/images/phone.webp" alt=""/>
                                    <div className="showcase-item-func">
                                        <span><img src="../website/public/images/header-wishlist.svg" alt="" /></span>
                                        <span><img src="../website/public/images/home-open.svg" alt="" /></span>
                                        <span><img src="../website/public/images/header-wishlist.svg" alt=""/></span>
                                    </div>
                                    <aside>
                                        <em>top product</em>
                                    </aside>
                            </figure>
                            <h6>product title and product name</h6>
                            <div>
                                <span>$000</span>
                                <em>$0000</em>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </section>
    )
};

export default ProductShowcase;