import { useEffect } from 'react';
import './reset.css';
import './App.css';
import './fonts/AktivGrotesk-Light.css';
import './fonts/AktivGrotesk-Light.ttf';
import AppRoutes from './routes/AppRoutes';

function App() {
  useEffect(() => {
  }, []);
  return (<AppRoutes />);
}

export default App;
