import { WishListSvg, OpenSvg, AddToCartSvg, ImagePlaceholderSvg } from "../../icons/Icon";
import { $discountPercentage } from "../../main";
import { IMAGE_HOST } from "../../api/Api";
import { Link } from "react-router-dom";
const ProductShowCase = (p) => {

    const addToWishList = (e, p) => {
        e.preventDefault();
        console.log("addToWishList", p);
    };
    const addToCart = (e, p) => {
        e.preventDefault();
        console.log("addToCart", p);
    };
    function renderProduct(products) {
        return (
            Array.isArray(products) && products.slice(0, 4).map((p, i) => {

                let image = null;
                if (Array.isArray(p.images) && (p.images.length > 0)) {
                    image = <img src={`${IMAGE_HOST}/${p.images[0].url}`} alt="" />
                } else {
                    image = <ImagePlaceholderSvg style={{ opacity: '0.1' }} />;
                }

                return (
                    <div key={`${i}-${p.id}`} className="product-showcase-item col-6">
                        <Link to={`/product/${p.productId}/${p.name.replaceAll(/\s+/g, '-')}`}>
                            <figure>
                                <div className="showcase-item-func">
                                    <span><WishListSvg onClick={(e) => addToWishList(e, p)} /></span>
                                    <span><OpenSvg /></span>
                                </div>
                                <aside>
                                    <span>{$discountPercentage(p.retailPrice, p.salePrice)}%</span>
                                </aside>
                            </figure>
                            <div className="image-block">{image}</div>
                            <h6>{p.name}</h6>
                            <div>
                                <span>&#8377;{p.retailPrice}</span>
                                <em>&#8377;{p.salePrice}</em>
                                <button><AddToCartSvg onClick={(e) => addToCart(e, p)} /></button>
                            </div>
                        </Link>
                    </div>
                );
            })
        );
    }
    return (
        <section className="product-showcase">
            <div className="product-showcase-head">
                <h3>{p.name || 'Category'}</h3>
                <a href="">View all</a>
            </div>
            <div className="product-showcase-body flex-row">
                {renderProduct(p.products || [])}
            </div>
        </section>
    );
}

export default ProductShowCase;